import { render } from "react-dom";
import { useState, StrictMode, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import ThemeContext from "./ThemeContext";
const LandingPage = lazy(() => import("./LandingPage"));
const Teams = lazy(() => import("./Teams"));
import logo from "../images/logo.jpg";
import Journey from "./Journey";

const App = () => {
  const theme = useState("darkblue");
  return (
    <StrictMode>
      <ThemeContext.Provider value={theme}>
        <Suspense fallback={<h1>loading route …</h1>}>
          <div className="header-bckg p-0 m-0">
            <BrowserRouter>
              <header className="w-full mb-1 lg:mb-5 text-left">
                {/*  <Link to="/" className="text-6xl text-blue-100 hover:uppercase">
                  <div className="logo">
                    <img key={logo} src={logo} alt="2nd Chance" />
                  </div>
                </Link>
               <div className="menu">
                  <Link to="/" className="text-xl hover:uppercase menu-item">
                    About US
                  </Link>
                  <Link to="/" className="text-xl hover:uppercase  menu-item">
                    Services
                  </Link>
                  <Link to="/" className="text-xl hover:uppercase  menu-item">
                    Events
                  </Link>
                  <Link to="/" className="text-xl hover:uppercase  menu-item">
                    Careers
                  </Link>
                  <Link to="/" className="text-xl hover:uppercase  menu-item">
                    Contact Us
                  </Link>
                </div>
                <div className="ham-menu">
                  <div className="space-y-2">
                    <div className="w-8 h-0.5 bg-gray-600">About US</div>
                    <div className="w-8 h-0.5 bg-gray-600">Services</div>
                    <div className="w-8 h-0.5 bg-gray-600">Events</div>
                    <div className="w-8 h-0.5 bg-gray-600">Careers</div>
                    <div className="w-8 h-0.5 bg-gray-600">Contact Us</div>
                  </div>
                </div> */}
                <nav className="container flex">
                  <Link
                    to="/"
                    className="text-6xl text-blue-100 hover:uppercase"
                  >
                    <div className="logo">
                      <img key={logo} src={logo} alt="2nd Chance" />
                    </div>
                  </Link>
                  <div className="menu hidden space-x-8 lg:flex">
                    <Link to="/" className="text-xl hover:uppercase menu-item">
                      About US
                    </Link>
                    <Link to="/" className="text-xl hover:uppercase  menu-item">
                      Services
                    </Link>
                    <Link to="/" className="text-xl hover:uppercase  menu-item">
                      Events
                    </Link>
                    <Link to="/" className="text-xl hover:uppercase  menu-item">
                      Careers
                    </Link>
                    <Link to="/" className="text-xl hover:uppercase  menu-item">
                      Contact Us
                    </Link>
                  </div>
                  <div className="flex lg:hidden">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </div>
                </nav>
              </header>
              <Routes>
                <Route path="/" element={<LandingPage />} />
              </Routes>
              <Routes>
                <Route path="/" element={<Teams />} />
              </Routes>
              <Routes>
                <Route path="/" element={<Journey />} />
              </Routes>
            </BrowserRouter>
          </div>
        </Suspense>
      </ThemeContext.Provider>
    </StrictMode>
  );
};

render(<App />, document.getElementById("root"));
