const Journey = () => {
  return (
    <div className="journey">
      <div className="story">YOUR JOURNEY</div>
      <div className="how-it-works">How It Works?</div>
      <div className="get-matched">
        Get matched to the best therapist for you
      </div>
      <div className="steps">
        <div className="step1">
          <div className="step-title">Step 1</div>
          <div className="step-desc">
            Who will help me take my second chance?
          </div>
        </div>
        <div className="divider"></div>
        <div className="communicate">Our Therapists and Coaches.</div>

        <div className="step2text">
          There are rooms that can hold up to 10 participants, and supervised by
          a coach, we will help you connect with the room needed.
        </div>
        <div className="step2card">
          <div className="step-title">Step 2</div>
          <div className="step-desc">
            How can I participate in group therapy?
          </div>
        </div>

        <div className="step1">
          <div className="step-title">Step 3</div>
          <div className="step-desc">
            How can I get matched with a therapist?
          </div>
        </div>
        <div className="communicate step3text">
          Based on your survey you fill upon sign up, the system will help you
          get matched with a therapist based on your preferences.
        </div>
      </div>
      <div className="mobile-steps">
        <div className="step1">
          <div className="step-title">Step 1</div>
          <div className="step-desc">
            Who will help me take my second chance?
          </div>
        </div>
        <div className="communicate">Our Therapists and Coaches.</div>

        <div className="step1">
          <div className="step-title">Step 2</div>
          <div className="step-desc">
            How can I participate in group therapy?
          </div>
        </div>
        <div className="communicate">
          There are rooms that can hold up to 10 participants, and supervised by
          a coach. We will help you connect with the relative group.
        </div>

        <div className="step1">
          <div className="step-title">Step 3</div>
          <div className="step-desc">
            How can I get matched with a therapist?
          </div>
        </div>
        <div className="communicate">
          Based on your survey you fill upon sign up, the system will help you
          get matched with a therapist based on your preferences.
        </div>
      </div>
    </div>
  );
};

export default Journey;
